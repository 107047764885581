<template>
<div class="flex flex-col" >
    <h2 class="text-red text-md">Tickets</h2>
    <div v-if="!ticket" class="flex my-2 w-full" >
        <SearchSelectInput  class="w-full" @item:removed="ticketRemoved" @item:selected="ticketSelected" :params="ticketSearchParams" :multiple="false" label="Add Ticket" v-model="tickets" :optiondisplay="ticketDisplay" :hideSelected="true"></SearchSelectInput>
    </div>
    <div v-if="ticket" class="flex flex-col my-2 w-full">
        <div class="w-full flex justify-between items-center">
            <button @click="ticket = false" class="border w-6 h-6 rounded-lg" >
                <i class="far fa-chevron-left"></i>
            </button>
            <button @click="$router.push({path: '/tickets/'+ticket.id})" class="border border-yellow bg-yellow w-24 h-6 rounded-lg" >
                Edit Ticket
            </button>
        </div>
        <TicketDescription :ticket="ticket" />
    </div>
    <div v-else class="flex flex-col my-2 w-full">
        <div v-for="(ticket,i) in tickets" :key="i" class="flex items-center justify-between my-2 hover:bg-yellow p-2" role="button" @click="ViewTicket(ticket)">
            <h3 class="font-purple">
                ID: {{ticket.id}} Title: {{ticket.title}}
            </h3>
            <span v-if="ticket.status == 'Open'" class="px-4 py-2 text-sm bg-purple border border-purple  text-white rounded-full" >{{ticket.status}}</span>
            <span v-else class="px-4 py-2 text-sm  bg-white text-purple border border-purple rounded-full" >{{ticket.status}}</span>
        </div>
    </div>
</div>
</template>

<script>
import TicketDescription from '../../components/dashboard/TicketDescription'
export default {
    name: 'Tickets',
    data: () => ({
        tickets: [],
        ticket: false,
        search: [],
        ticketSearchParams: {
            'model': 'Ticket',
            'fields': ['id','title','description'],
            'action': '/search/',
        },
    }),
    computed: {
    },
    props: ['model'],
    methods: {
        ViewTicket(ticket) {
            this.ticket = ticket
        },
        ticketDisplay(item) {
            return 'ID: ' + item.id + ' Title: ' + item.title;
        },
        ticketSelected(evt) {
            let that = this
            this.$requests.post('/ticket/assign',{
                ticket_id: evt.item.id,
                model: 'Customer',
                model_id: that.model.id
            },
            {
                success: () => {
                    let index = that.tickets.findIndex((ticket)=>{return ticket.id == evt.item.id})
                    if(index < 0) that.tickets.push(evt.item);
                    return true
                },
            })
        },
        GetTickets() {
            let that = this
            this.$requests.post('/tickets',{
                model: 'Customer',
                model_id: that.model.id
            },
            {
                success: (re) => {
                    that.tickets = re.data.items
                    return true
                },
            })
        },
        ticketRemoved(evt) {
            this.ticket.splice(evt.index,1);
        },
    },
    watch: {
    },
    created() {
        this.GetTickets()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        TicketDescription,
    },
}
</script>
<style scoped>
</style>
