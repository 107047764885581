<template>
<div class="bg-white shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ticket.title}}
        </h3>
        <p class="my-4 max-w-2xl text-sm text-gray-500">
            <button v-if="show !='comments'" @click="show = 'comments'" class="bg-yellow px-4 py-2 border border-yellow text-black">
                View Comments
            </button>
            <button v-else @click="show = 'ticket'" class="bg-yellow px-4 py-2 border border-yellow text-black">
                Close Comments
            </button>
        </p>
    </div>
    <div v-if="show == 'comments'" class="border-t border-gray-200">
        <Comments :model="{id:ticket.id,type:'Ticket'}"></Comments>   
    </div>
    <div v-else class="border-t border-gray-200" >
        <dl>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
            Status
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span class="px-2 py-1 text-sm bg-purple border border-purple  text-white rounded-full" >{{ticket.status}}</span>
            </dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
            Tags
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span v-for="(tag,i) in ticket.tags" :key="i" class="px-2 py-1 font-sm bg-white border border-purple text-purple rounded-full" >{{tag.name}}</span>
            </dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
            Assignees
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <p v-for="(assignee,i) in ticket.users" :key="i">
                    {{assignee.email}}
                </p>
            </dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
            Due Date
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{getDate(ticket.due_date)}}
            </dd>
        </div>
        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
            Description
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <wysiwyg :disabled="true" v-model="ticket.description" />
            </dd>
        </div>
        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
            Attachments
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <Attachments v-model="ticket.attachments" :model="{type:'Ticket',id: ticket.id}"/>
            </dd>
        </div>
        </dl>
    </div>
</div>
</template>
<script>
import moment from 'moment'
import Comments from './Comments'
export default {
    name: 'TicketDescription',
    data: () => ({
        show: 'ticket',
    }),
    computed: {
    },
    props: ['ticket'],
    methods: {
        getDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        Comments,
    },
}
</script>
<style scoped>
</style>
