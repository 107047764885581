<template>
<div class="container pt-16 px-2 ">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Customers
        </h1>
        <LinkTemplate :to="{path:'/customers/create'}" class="my-6">
            Add New Customer
        </LinkTemplate>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="items.length"  class="">
        <TableTemplate  v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/customers/" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
    </div>
    <div  v-else  class="">
        <TableTemplate  v-if="loading == false" @item:deleted="ItemDeleted" :candelete="false" canview="/customers/" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
    </div>
</div>
</template>

<script>
import List from '../../../mixins/List'
export default {
    name: 'CustomerList',
    mixins: [List],
    data: () => ({
        limit: 10,
        page: 0,
        action: '/customers',
        searchParams: {
            'model': 'Customer',
            'fields': ['id','email','last_name','first_name','username'],
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'last_name',
                label:'Last Name',
            },
            {
                id:'first_name',
                label:'First Name',
            },
            {
                id:'email',
                label:'Email',
            },
            {
                id:'username',
                label:'Username',
            },
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
